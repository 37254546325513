.leftEye {
  width: 30px;
  // animation: spin 4s linear;
}
// @keyframes spin {
//   100% {
//     -webkit-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }
