.face {
  position: relative;
  width: 250px;

  .sheaFace {
    width: 250px;
  }

  .leftEye {
    position: absolute;
    left: 125px;
    top: 93px;
  }

  .rightEye {
    position: absolute;
    left: 170px;
    top: 95px;
  }
}
